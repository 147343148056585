/**
Basic Custom Styles
 */
 .contact-dialog{
    max-width: 1140px;
 }
/* slider */
@keyframes plane {
    0% {
        top: 30%;
        right: 15%;
    }
    14.3% {
        top: 30%;
        right: 15%;
    }
    28.6% {
        top: 25%;
        right: 12%;
    }
    57.2% {
        top: 30%;
        right: 15%;
    }
    71.5% {
        top: 30%;
        right: 10%;
    }
    85.8% {
        top: 25%;
        right: 15%;
    }
    100% {
        top: 30%;
        right: 15%;
    }
}

.star {
    position: absolute;
    top: 0;
    right: 0;
    animation-name: fall;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
}

@keyframes fall {
    100% {
        transform: translate(-300vh, 200vh);
    }
}

.layer1 {
    top: 30%;
    right: 0;
    animation-duration: 8s;
}

.layer1.a {
    right: -10%;
    animation-delay: 1s;
}

.layer1.b {
    right: -2%;
    animation-delay: 2s;
}

.layer1.c {
    right: -8%;
    animation-delay: 4s;
}

.layer1.d {
    right: -2%;
    animation-delay: 6s;
}

.layer2 {
    top: -20%;
    right: 0;
    animation-duration: 8s;
}

.layer2.a {
    right: -2%;
    animation-delay: 3s;
}

.layer2.b {
    right: -9%;
    animation-delay: 4s;
}

.layer2.c {
    right: -13%;
    animation-delay: 6s;
}

.layer2.d {
    right: -15%;
    animation-delay: 2s;
}

@keyframes fall {
    100% {
        transform: translate(-300vh, 200vh);
    }
}

.plane {
    position: absolute;
    animation: plane 4s linear infinite;
}

@media (min-width: 1530px) {
    .top-font {
        font-size: 80px !important;
    }
}

@media (min-width: 1200px) and (max-width: 1529px) {
    .top-font {
        font-size: 50px !important;
    }
}

.main-button a img{
    filter: brightness(0) invert(1);
}

.main-button a:hover img{
    filter: none;
}


.contact-form-ui input{
    height: 39px;
}

.mySwiper .swiper-pagination{
    position: unset;
    margin-top: 30px;
}
.mySwiper .swiper-pagination-bullet{
    width: 16px;
    height: 16px;
    background-color: transparent;
    border: 1px solid #006B5A !important;
    opacity: 1;
}
.mySwiper .swiper-pagination-bullet-active{
    background-color: #006B5A !important;
    width: 30px;
    border-radius: 8px;
}
.pac-container {
    z-index: 2001!important;
}
  
  .cover-image-overlay {
    background-color: rgba(0, 0, 0, 0.5); /* Adjust the opacity as needed */
  }
  
  
  .play-button {
    transform: translate(-50%, -50%);
  }

@media (min-width: 1440px) {
    .container {
        max-width: 1405px !important;
        padding: 0 15px;
    }
}


@media (min-width: 1200px) and (max-width: 1439px) {
    .container {
        max-width: 1280px !important;
        padding: 0 15px;
    }
}

@media (min-width: 1200px) and (max-width: 1409px) {
    .top-banner h2 {
        font-size: 60px !important;
        margin-bottom: 50px;
    }

    .slider-img .plane {
        max-width: 300px;
    }

    .slider-social {
        margin-left: 0 !important;
    }
}

@media (max-width: 1409px) {
    .custom-pedding {
        padding: 0 15px;
    }

    .feature-part, .achievement, .contact {
        margin-left: -15px;
        margin-right: -15px;
        padding-left: 15px;
        padding-right: 15px;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .plan-base, .count-unit, .mdflex {
        display: flex !important;
    }

    .count-unit {
        justify-content: space-around !important;
    }

    .plan-base .shadow {
        margin: 0 2px !important;
    }

    .top-banner h2 {
        font-size: 50px !important;
        margin-bottom: 40px;
    }
}
@media(max-width: 991px){
    .top-banner{
        padding-bottom: 100px;
    }
}
@media(max-width: 1199px){
    .container {
        width: 100% !important;
        padding: 0 15px;
        max-width: unset;
    }
}
@media (min-width: 992px) and (max-width: 1199px) {
    .top-banner {
        height: calc(100vh - 130px);
    }
    .count-unit .border-white {
        display: inline-block !important;
        padding: 20px;
        width: 25%;
        margin: 0 5px;
    }

    .count-unit {
        justify-content: space-around !important;
    }

    .top-banner h2 {
        font-size: 50px !important;
        margin-bottom: 40px;
    }

    .slider-img .plane {
        max-width: 300px;
    }

    .slider-social {
        margin-left: 0 !important;
    }
}

@media (max-width: 599px) {
    .top-banner h2 {
        font-size: 30px !important;
        margin-bottom: 40px;
        line-height: normal !important;
    }
    .custom-drawer .MuiDrawer-paper{
        min-width: 200px;
    }
}

@media (min-width: 600px) and (max-width: 767px) {
    .top-banner h2 {
        font-size: 50px !important;
        margin-bottom: 40px;
        line-height: normal !important;
    }
}

.policy p {
    margin-top: 0;
    margin-bottom: 12px
}

.my-custom-prose h2 {
    margin-top: 0;
}

.background-image{
    background-image: url('../../public/assets/images/cards/Group35084.png');
    object-fit: contain;
    height: 300px;
}

.background-image-set{
    background-image: url('../../public/assets/images/cards/Group35085.png');
    background-size: contain;
    height: 536px;
}

.select-text .muiltr-1kd9u3t-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select {
    display: flex;
    justify-content: center;
    align-items: center;
}

.swiper-pagination {
    top: 80px !important;
}

input[type=number] {
    -moz-appearance: textfield !important;
}

.phone-input-error .react-tel-input .form-control {
    border-color: red !important;
  }
  
  .phone-input-error .react-tel-input .flag-dropdown {
    border-color: red !important;
  }